/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-plusplus */
import { format } from 'date-fns';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const setCookie = (name, value, days, domain) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  const domainString = domain ? `; domain=${domain}` : '';
  document.cookie = `${name}=${value || ''}${expires}; path=/${domainString}`;

  return true;
};

export const getCookie = name => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const clearCookie = name => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

  return true;
};

export const capitalize = (str = '') => {
  const arr = str?.toLowerCase().split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(' ');
  return str2;
};

function changeTimezone(date, ianatz) {
  // suppose the date is 12:00 UTC
  const invdate = new Date(
    date.toLocaleString('en-US', {
      timeZone: ianatz,
    }),
  );

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  const diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract
}

export const getDateObject = e => changeTimezone(new Date(e), 'Canada/Eastern');

export const GeoCode = async value => {
  try {
    const { results } = typeof window !== 'undefined' && (await new window.google.maps.Geocoder().geocode(value));

    if (!results) {
      throw Error('Unable to load maps');
    }
    const { address_components, geometry, place_id, formatted_address, types } = results[0];
    const address = {};
    // eslint-disable-next-line no-shadow
    address_components?.forEach(({ short_name, types }) => {
      if (types.includes('administrative_area_level_1')) {
        address.state = short_name;
      } else if (types.includes('administrative_area_level_2')) {
        address.county = short_name;
      } else if (types.includes('locality')) {
        address.city = short_name;
      } else address[types[0]] = short_name;
    });

    return {
      ...address,
      types,
      place_id,
      latlng: {
        lat: geometry?.location?.lat(),
        lng: geometry?.location?.lng(),
      },
      formatted_address,
    };
  } catch (err) {
    throw Error(err?.message ?? 'Unable to load maps');
  }
};

export const permissionsToIgnore = [
  'dashboard.nav',
  'employees-management.nav',
  'inventory-management.nav',
  'store-setting.nav',
  'account-setting.nav',
];

export function formatDateAndTime(isoDate) {
  const date = new Date(isoDate);
  const day = date.getDate();
  const month = date.toLocaleString('en-us', { month: 'short' });
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const period = hours >= 12 ? 'PM' : 'AM';

  return {
    date: `${day} ${month}, ${year}`,
    time: `${((hours + 11) % 12) + 1}:${minutes} ${period}`,
  };
}
export const convertArrayOfObjectsToCSV = data => {
  const headers = [
    'serialNumber',
    'transactionNumber',
    'buyer_name',
    'buyer_email',
    'buyer_phone',
    'totalItems',
    'date',
    'totalSales',
    'products',
  ];

  let csv = `${headers.join(',')}\n`;

  // Iterate over each object in the array
  data.forEach(obj => {
    // Extract values for each object
    const row = [
      obj.serialNumber,
      obj.transactionNumber,
      obj.buyer_info.name,
      obj.buyer_info.email,
      obj.buyer_info.phone,
      obj.totalItems,
      format(new Date(obj.date), 'yyyy-MM-dd'),
      obj.totalSales,
    ];

    // Create a string for products in the transaction
    const productsString = obj.products
      .map(product => `Name: ${product.product_name}; Price: ${product.price} `)
      .join(';');

    // Add the products string to the row
    row.push(productsString);

    // Join row elements with commas and add to CSV
    csv += `${row.join(',')}\n`;
  });

  return csv;
};

export const exportData = (data, fileName) => {
  const csvContent = convertArrayOfObjectsToCSV(data);
  const csvBlob = new Blob([csvContent], { type: 'text/csv' });

  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(csvBlob);
  downloadLink.download = `${fileName}.csv`;
  downloadLink.click();
};

export const validatePhoneNumber = value => {
  let num;

  if (value?.startsWith('+92')) {
    num = 14;
  } else if (value?.startsWith('+45')) {
    num = 12;
  } else if (value?.startsWith('+1')) {
    num = 13;
  } else {
    num = 0;
  }

  return num;
};

export const isPhoneValid = value => isValidPhoneNumber(value);

export const formatNumber = number => new Intl.NumberFormat().format(number);
