/* eslint-disable no-restricted-imports */
import styled from 'styled-components';

export const StyledTopBar = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 15px;
  background: var(--white);
  box-shadow: 0px 2px 20px 0px rgba(18, 32, 59, 0.09);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;

  @media (min-width: 1200px) {
    padding: 12px 40px;
  }

  .menu-holder {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .nav-btn {
    display: block;
    position: relative;
    width: 36px;
    height: 36px;
    /* border: 1px solid var(--primary); */
    border-radius: 5px;
    cursor: pointer;
    z-index: 9;
    transition: all ease-in-out 0.3s;

    @media (min-width: 1200px) {
      display: none;
    }

    .nav-active & {
      span {
        opacity: 0;
      }

      &:after,
      &:before {
        transform: rotate(45deg);
        top: 50%;
        left: 15%;
        right: 15%;
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    &:before,
    &:after,
    span {
      background: var(--primary);
      border-radius: 4px;
      position: absolute;
      top: 50%;
      left: 15%;
      right: 15%;
      height: 3px;
      margin-top: -2px;
      transition: all 0.2s linear;
    }

    &:before,
    &:after {
      content: '';
      top: 30%;
    }

    &:after {
      top: 70%;
      right: 15%;
    }

    span {
      right: 15%;
    }

    &:hover {
      opacity: 0.9;
    }
  }
  .logoWrapper {
    max-width: 80px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .textField {
    display: flex;
    max-width: 561px;
    width: 100%;
    padding: 0 16px;
    align-items: center;
    gap: 10px;
    border-radius: 60px;
    border: 1px solid var(--gray-2);
    background: var(--white);
    input {
      height: 40px;
      width: 100%;
      border: none;
      outline: none;
    }
  }
  .notification {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    gap: 4px;
    border-radius: 60px;
    background: rgba(17, 16, 18, 0.1);
    color: var(--dark);
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    cursor: pointer;

    @media (min-width: 768px) {
      padding: 8px 14px;
    }

    .text {
      @media (max-width: 767px) {
        display: none;
      }
    }
    /* &:hover {
      .notificationWrapper {
        visibility: visible;
        transform: translateY(0);
        opacity: 1;
      }
    } */
  }
  .notificationWrapper {
    max-width: 432px;
    position: absolute;
    top: 0;
    right: 30px;
    padding-top: 19px;
    /* visibility: hidden; */
    transform: translateY(50px);
    /* opacity: 1; */
    transition: 0.4s;
  }
  .user-info {
    display: flex;
    align-items: center;
    gap: 10px;
    @media (min-width: 768px) {
      gap: 30px;
    }
  }
`;
export const UserInfo = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  gap: 5px;
  width: 62px;
  height: 40px;
  padding: 4px 6px;
  cursor: pointer;
  border-radius: 60px;
  background: rgba(255, 255, 255, 0.2);

  &:hover {
    .user-dropdown {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
    .icon {
      transform: scale(-1);
    }
  }

  .icon {
    flex-shrink: 0;
    transform: scale(1);
    transition: 0.4s;
  }

  .user-img {
    flex-shrink: 0;
    width: 29px;
    height: 29px;
    border-radius: 50px;
    overflow: hidden;
    cursor: pointer;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .user-dropdown {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: translateY(50px);
    position: absolute;
    top: 100%;
    right: 0;
    width: 215px;
    font-size: 14px;
    line-height: 18px;
    color: var(--matte-black);
    padding: 15px;
    border-radius: 10px;
    background: var(--white);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    @media (min-width: 576px) {
      width: 310px;
      padding: 20px;
      border-radius: 20px;
    }

    .wrap {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      margin: 0 0 10px;
      @media (min-width: 576px) {
        margin: 0 0 20px;
      }
    }

    .img-box {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      overflow: hidden;
      @media (min-width: 576px) {
        width: 65px;
        height: 65px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text-box {
      flex-grow: 1;
      padding-top: 3px;
    }

    .title {
      display: block;
      text-transform: capitalize;
      font-weight: 600;
      margin: 0 0 3px;
    }

    .email {
      display: block;
      margin: 0 0 10px;
    }

    button {
      text-transform: capitalize;
      font-weight: 400;
    }

    .list {
      list-style: none;
      margin: 0 0 10px;
      padding: 8px 0;
      border-width: 1px 0;
      border-style: solid;
      border-color: #c2c9d1;
      @media (min-width: 576px) {
        margin: 0 0 16px;
      }

      li {
        padding: 5px 0;
        @media (min-width: 576px) {
          padding: 8px 0;
        }
      }

      a {
        display: inline-flex;
        align-items: center;
        gap: 5px;
        color: var(--body-text);

        &:hover {
          color: var(--primary);
        }
      }
    }

    .btn-logout {
      display: flex;
      align-items: center;
      gap: 5px;
      color: var(--danger);
    }
  }
`;

export const NotificationInfo = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  gap: 5px;
  width: 207px;
  height: 40px;
  padding: 4px 6px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  &.active {
    .dropdown-holder {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 10px;
    @media (min-width: 768px) {
      gap: 30px;
    }
  }

  .notification {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    gap: 4px;
    border-radius: 60px;
    background: rgba(17, 16, 18, 0.1);
    color: var(--dark);
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    cursor: pointer;

    @media (min-width: 768px) {
      padding: 8px 14px;
    }

    .text {
      @media (max-width: 767px) {
        display: none;
      }
    }

    .img-holder {
      display: flex;
      position: relative;

      .notification-count {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.6);
        min-width: 20px;
        height: 20px;
        position: absolute;
        top: -20px;
        left: 5px;
        border-radius: 25px;
        font-size: 11px;
        color: #fff;
      }
    }
  }

  .dropdown-holder {
    transition: all 0.3s ease-in-out;
    transform: translateY(50px);
    opacity: 0;
    position: absolute;
    top: 45px;
    right: 76px;
    width: 450px;
  }
`;
