import React, { useContext, useRef } from 'react';
import { SocketContext } from 'context/socketContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCookie } from 'helpers/common';
import { ImageHolder, NotificationsHolder } from './Notifications.styles';

const Notifications = ({ data, setSearchQuery, totalItems }) => {
  const elementRef = useRef();
  const token = getCookie('_loadpzt');

  const { socket } = useContext(SocketContext);

  const fetchMore = () => {
    setSearchQuery(prevState => ({
      ...prevState,
      itemsPerPage: prevState.itemsPerPage + 4,
    }));
  };

  const handleMarkAsRead = notificationId => {
    socket.emit('mark-as-read', { notificationId, token });
  };

  const handleKeyDown = (event, notificationId) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleMarkAsRead(notificationId);
    }
  };

  return (
    // eslint-disable-next-line no-unneeded-ternary
    <NotificationsHolder ref={elementRef} id="NotificationCardContainer" boxHei={data?.length === 0 ? true : false}>
      {data?.length === 0 ? (
        <span className="loading">No notifications found.</span>
      ) : (
        data?.map((item, index) => (
          <div
            key={index}
            className="holder"
            onClick={() => handleMarkAsRead(item._id)}
            onKeyDown={e => handleKeyDown(e, item._id)}
            role="button"
            tabIndex={0}>
            {item.isRead === false && <span className="active-dot" />}
            <div className="notification">
              <div className="content">
                <ImageHolder background={item?.background}>
                  <img src={item?.icon} alt="notification" />
                </ImageHolder>
                <div>
                  <span className="heading">{item?.message}</span>
                  <div className="date-time">
                    <span className="date">{item?.date}</span>
                    <span>{item?.time}</span>
                  </div>
                </div>
              </div>
              {item.tag && (
                <div className="tag">
                  <span>{item?.tag}</span>
                </div>
              )}
            </div>
          </div>
        ))
      )}

      {data?.length > 0 && (
        <InfiniteScroll
          dataLength={data?.length ?? 0}
          next={fetchMore}
          hasMore={data?.length < totalItems}
          scrollableTarget="NotificationCardContainer"
          loader={<span className="loading">Loading...</span>}
        />
      )}
    </NotificationsHolder>
  );
};

export default Notifications;
