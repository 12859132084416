import styled from 'styled-components/macro';

export const NotificationsHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.boxHei ? 'center' : '')};
  align-items: center;
  gap: 15px;
  width: 100%;
  border-radius: 20px;
  background: var(--white);
  padding: 25px 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: ${props => (props.boxHei ? '85px' : '300px')};
  overflow-y: ${props => (props.boxHei ? 'hidden' : 'scroll')};

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);
    font-size: 18px;
  }
  .holder {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    /* padding: 14px 0; */
    border-bottom: 1px solid var(--gray-150);
    &:nth-child(1) {
      padding-top: 0;
    }
    &:nth-last-child(1) {
      border: 0;
      padding-bottom: 0;
    }
    .active-dot {
      min-width: 7px;
      height: 7px;
      border-radius: 50%;
      background: red;
    }
  }
  .notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .content {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--matte-black);
      .heading {
        font-weight: 600;
      }
      .date-time {
        display: flex;
        gap: 15px;
        .date {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -8px;
            width: 1px;
            height: 12px;
            background: var(--matte-black);
          }
        }
      }
    }
    .tag {
      padding: 5px 10px;
      border-radius: 60px;
      color: var(--success);
      background: rgba(76, 175, 80, 0.1);
    }
  }
`;
export const ImageHolder = styled.div`
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background && background};
`;
