import { useEffect, useState } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};
const adminService = {
  _url: `${process.env.REACT_APP_ADMIN_API_URL}/api/admin`,
  /**
   * Hooks
   */

  GetAdmins(searchQuery, refetch) {
    const [admins, setAdmins] = useState({
      admins: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAdmins(searchQuery))
        .then(res => {
          setAdmins(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterRoles,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.searchText,
      searchQuery?.sort,
      refetch,
    ]);
    return {
      admins_loading: status === STATUS.LOADING,
      admins_error: status === STATUS.ERROR ? status : '',
      admins_data: admins,
    };
  },
  GetAffiliateAdmins(searchQuery, refetch) {
    const [admins, setAdmins] = useState({
      admins: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAffiliateAdmins(searchQuery))
        .then(res => {
          setAdmins(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterRoles,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.searchText,
      searchQuery?.sort,
      refetch,
    ]);
    return {
      admins_loading: status === STATUS.LOADING,
      admins_error: status === STATUS.ERROR ? status : '',
      admins_data: admins,
    };
  },

  async health() {
    const res = await Fetch.get(`${this._url}/health`);
    if (res.status >= 200 && res.status < 300) {
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getAdmins({ page = 1, pageSize = 10, searchText, filterRoles, startDate, endDate, getAll = false, sort = '' }) {
    let res = await Fetch.get(
      `${this._url}/get-all-admins?itemsPerPage=${pageSize}&page=${page}&searchText=${searchText}&filterRoles=${filterRoles}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}&sort=${sort}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        admins: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAffiliateAdmins({
    page = 1,
    pageSize = 10,
    searchText,
    filterRoles,
    startDate,
    endDate,
    getAll = false,
    sort = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/get-all-affiliate-admins?itemsPerPage=${pageSize}&page=${page}&searchText=${searchText}&filterRoles=${filterRoles}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}&sort=${sort}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        admins: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async login({ email = '', password = '' }) {
    let res = await Fetch.post(`${this._url}/signin`, { email, password });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async deleteAdmin(id) {
    let res = await Fetch.delete(`${this._url}/delete-admin/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async addAdmin(values) {
    let res = await Fetch.post(`${this._url}/add-admin`, values);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async updateAdmin(id, values) {
    let res = await Fetch.patch(`${this._url}/update-admin/${id}`, values);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async removeAdminJwt() {
    let res = await Fetch.delete(`${this._url}/logout`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getCurrentAdmin() {
    let res = await Fetch.get(`${this._url}/perms`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async createRole(payload) {
    let res = await Fetch.post(`${this._url}/role`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async resendOtp({ email = '' }) {
    let res = await Fetch.post(`${this._url}/resend-otp`, {
      email,
    });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async verifyOtp({ email = '', otp = '' }) {
    let res = await Fetch.post(`${this._url}/verify-otp`, {
      email,
      otp,
    });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async resetPassword(payload) {
    let res = await Fetch.put(`${this._url}/reset-password`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getSessionCount() {
    let res = await Fetch.get(`${this._url}/get-session-count`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res?.sessionCount;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateSessionCount(payload) {
    let res = await Fetch.put(`${this._url}/update-session-count`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async sendDetailsEmail({ store }) {
    let res = await Fetch.post(`${this._url}/store/send-details-email`, store);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};
export default adminService;
