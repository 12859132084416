import React, { useEffect, useMemo } from 'react';
import { useContextHook } from 'use-context-hook';
// eslint-disable-next-line no-unused-vars

// import { useMediaPredicate } from 'react-media-hook';
import { SideNavContext } from 'context/sideNavContext';
import { LoadingContext } from 'context/loadingContext';

import { AuthContext } from 'context/authContext';
import sideNavData from 'nav.json';
import { SideNavbar, Nav, Ul } from './SideNav.styles';
import SubMenu from './SubMenu';

function Navbar() {
  const { sideNavState } = useContextHook(SideNavContext, v => ({
    toggleSideNav: v.toggleSideNav,
    sideNavState: v.sideNavState,
  }));
  const { allowedPages } = useContextHook(AuthContext, v => ({
    allowedPages: v.allowedPages,
  }));
  const { isLoading } = useContextHook(LoadingContext, v => ({
    isLoading: v.isLoading,
  }));
  // const MaxWidth991 = useMediaPredicate('(max-width: 991px)');
  useEffect(() => !sideNavState && document.body.classList.remove('nav-active'), [sideNavState]);
  const sideBarItems = useMemo(
    () =>
      sideNavData
        .filter(
          ({ file, live }) =>
            allowedPages.includes(file) && (process?.env?.REACT_APP_MAIN_URL === 'https://plastk.ca' ? live : true),
        )
        .map((item, index) => <SubMenu item={item} key={index} />),
    [isLoading, sideNavData, allowedPages],
  );
  return (
    <>
      <SideNavbar css={isLoading && 'background:var(--dark);'} $loading={isLoading}>
        {/* {MaxWidth991 && (
          <CloseButton onClick={toggleSideNav}>
            <i className="material-icons-outlined">close</i>
          </CloseButton>
        )} */}
        <Nav id="sub-menu">
          <Ul>{sideBarItems}</Ul>
        </Nav>
      </SideNavbar>
    </>
  );
}
export default Navbar;
