import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import adminService from 'services/adminService';
import OnboardingTemplate from 'components/OnboardingTemplate';
import { clearCookie, getCookie } from '../helpers/common';
import arrowImg from '../assets/images/half-blue-arrow.svg';
import Button from '../components/atoms/Button';
import Form, { useForm } from '../components/molecules/Form';
import { StyledForm } from '../components/organisms/Login/Login.styles';
import Toast from '../components/molecules/Toast';
import Heading from '../components/atoms/Heading';
import Field from '../components/molecules/Field';

function CreateNewPassword() {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const email = getCookie('email');
  const router = useHistory();

  const handleResetPassword = async val => {
    const updatedVal = {
      ...val,
      email,
    };
    try {
      setIsLoading(true);
      const res = await adminService.resetPassword(updatedVal);
      if (res) {
        clearCookie('email');
        clearCookie('request');
        setIsLoading(false);
        Toast({
          type: 'success',
          message: res?.message,
        });
        router.push('/signin');
      }
    } catch (ex) {
      setIsLoading(false);
      Toast({
        type: 'error',
        message: ex?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <OnboardingTemplate>
      <StyledForm form={form} onSubmit={handleResetPassword}>
        <Heading level={1} color="var(--white)" className="create-password-heading">
          Create New Password
        </Heading>
        <Form.Item
          label="Enter New Password"
          type="password"
          rounded
          name="new_password"
          placeholder="New Password"
          rules={[
            {
              required: true,
              message: 'New Password is required',
            },
            { password: true },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          type="password"
          rounded
          name="confirm_password"
          placeholder="Confirm Password"
          rules={[
            {
              required: true,
              message: 'Confirm Password is required',
            },
            {
              transform: value => {
                if (value !== form.getFieldValue('new_password')) {
                  return true;
                }
                return false;
              },
              message: 'Passwords do not match!',
            },
          ]}>
          <Field />
        </Form.Item>
        <div className="btn-holder">
          <Button type="white" htmlType={isLoading ? 'button' : 'submit'} width="250" rounded loader={isLoading}>
            Save Password
            <img src={arrowImg} alt="arrow" />
          </Button>
        </div>
      </StyledForm>
    </OnboardingTemplate>
  );
}

export default CreateNewPassword;
