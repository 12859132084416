import React, { useState } from 'react';
import { createContextHook } from 'use-context-hook';

export const FiltersContext = createContextHook({});

export const FiltersContextProvider = props => {
  const [filterToggle, setFilterToggle] = useState(false);

  const toggleFilter = () => {
    setFilterToggle(!filterToggle);
  };

  return (
    <FiltersContext.Provider
      value={{
        filterState: filterToggle,
        toggleFilter,
        setFilterToggle,
      }}>
      {props.children}
    </FiltersContext.Provider>
  );
};
