/* eslint-disable no-unused-vars */
import React from 'react';
import { useContextHook } from 'use-context-hook';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Heading from 'components/atoms/Heading';
import Button from 'components/atoms/Button';
import OnboardingTemplate from 'components/OnboardingTemplate';
import { NavLink } from 'react-router-dom';
import arrowImg from '../assets/images/half-blue-arrow.svg';
import LoginTemplate from '../components/templates/LoginTemplate';
import Field from '../components/molecules/Field';
import Form, { useForm } from '../components/molecules/Form';
import { StyledForm } from '../components/organisms/Login/Login.styles';

function Login() {
  const [form] = useForm();
  const { onLogin, loading_user } = useContextHook(AuthContext, v => ({
    onLogin: v.onLogin,
    loading_user: v.loading_user,
  }));
  return (
    <>
      <OnboardingTemplate>
        <Heading level={1} color="var(--white)">
          Sign In
        </Heading>
        <StyledForm form={form} onSubmit={onLogin}>
          <Form.Item
            type="text"
            label="Email Address"
            name="email"
            placeholder="Your Email or Username"
            rules={[{ required: true }]}>
            <Field />
          </Form.Item>
          <Form.Item
            type="password"
            label="Password"
            name="password"
            placeholder="Your Password"
            rules={[
              {
                required: true,
              },
            ]}>
            <Field />
          </Form.Item>
          <div className="flex-wrap">
            <Form.Item label="Remember Me" type="checkbox" rounded name="rememberMe">
              <Field />
            </Form.Item>
            <NavLink to="/reset-password">Forgot Password?</NavLink>
          </div>
          <div className="btn-holder">
            <Button loader={loading_user} type="white" rounded htmlType="submit" width={150}>
              Sign In
              <img src={arrowImg} alt="arrow" />
            </Button>
          </div>
        </StyledForm>
      </OnboardingTemplate>
    </>
  );
}

export default Login;
