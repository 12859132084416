// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components';

export const StyledTexfield = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;

  ${({ $hasIcon }) =>
    $hasIcon &&
    css`
      padding: 12px 60px;
    `}
`;
export const IconWrapper = styled.span`
  position: absolute;
  top: 12px;
  left: 20px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    max-width: 100%;
    height: auto;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 63px;
  font-family: var(--base-font-sans-serif);
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: var(--matte-black);
  padding: 15px 30px;
  border: none;
  outline: none;
  border-radius: 60px;
  background: var(--white);

  &::placeholder {
    color: var(--gray-50);
  }
`;
export const PhoneWrapper = styled.div`
  .PhoneInputCountrySelect {
    background: var(--white);
    padding: 20px;
    flex-shrink: 0;
    border: 0px;
    outline: none;
    display: block;
    border-radius: 50px;
    @media screen and (max-width: 576px) {
      padding: 10px;
    }
  }
  .PhoneInputCountry {
    padding: 10px;
    background: var(--white);
    border-radius: 60px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @media screen and (max-width: 576px) {
      width: 70px;
      border-radius: 20px;
    }
  }
  .PhoneInput {
    input {
      box-sizing: border-box;
      background: var(--white);
      border: none;
      outline: none;
      height: 50px;
      padding: 12px 20px;
      border-radius: 60px;
      color: var(--matte-black);
      font-size: 18px;
      line-height: 22px;
      font-style: normal;
      font-weight: 500;
      font-family: var(--base-font-sans-serif);
      &::placeholder {
        color: var(--gray-50);
      }
      @media screen and (max-width: 576px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`;
export const OtpInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  input {
    max-width: 88px;
    border: 0.8px solid var(--gray-150);
    border-radius: 20px;
    padding: 25px 35px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    &::placeholder {
      font-size: 35px;
      line-height: 20px;
      color: var(--gray-150);
    }
  }
`;
