import styled from 'styled-components/macro';

export const TemplateHolder = styled.div`
  min-height: 100vh;
  height: 100%;
  position: relative;
  z-index: 1;
  background-color: #36469a;
`;

export const ContentHolder = styled.div`
  width: 100%;
`;
