import styled from 'styled-components/macro';
import Link from 'components/atoms/Link';
import Form from 'components/molecules/Form';

export const SubTitle = styled.span`
  color: var(--light-gray);
  margin-bottom: 40px;
  display: block;
  @media (min-width: 768px) {
    margin-bottom: 80px;
  }
`;

export const LoginHead = styled.div`
  display: flex;
  display: none;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 30px;
  @media (min-width: 768px) {
    padding: 40px 80px;
  }
  h1 {
    margin-bottom: 40px;
  }
`;

export const StyledForm = styled(Form)`
  text-align: left;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 20px;
  @media (min-width: 576px) {
    font-size: 20px;
    line-height: 24px;
  }
  label {
    color: var(--white);
  }
  p {
    color: var(--white);
    text-align: center;
    margin-bottom: 35px;
  }
  .flex-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
    margin-bottom: 10px;
  }
  .text-holder {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    padding: 0 10px;
    text-align: center;
    color: var(--white);
    font-weight: 300;
    margin-bottom: 10px;
  }
  .btn-holder {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }
  .otp-input-holder {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
    margin: 0 0 15px;

    input {
      width: 14%;
      height: 63px;
      padding: 10px;
      font-family: var(--font-family-base);
      font-size: var(--font-size-base);
      font-weight: 600;
      text-align: center;
      border-radius: 10px;
      border: 0;
      box-shadow: none;
      outline: none;
      background: #e7e7e7;
    }
  }
  h1 {
    text-align: center;
  }
  .create-password-heading {
    margin-bottom: 35px;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    button {
      color: var(--blue);
    }
  }
  .timer {
    display: block;
    color: var(--white);
  }
  .resend-code:disabled {
    color: #288ba1;
    cursor: not-allowed;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: var(--light-gray);
  &:hover {
    i {
      transform: translateX(-5px);
    }
  }
  i {
    color: var(--primary);
    transition: transform 0.3s linear;
  }
`;
