import React from 'react';
import logo from '../../assets/images/PayBlisss.svg';
import Img01 from '../../assets/images/Img01.png';
import shapeImg1 from '../../assets/images/blur-shape.svg';
import shapeImg from '../../assets/images/bg-shape1.svg';

import { OnboardingBlock, FormCol, BannerCol } from './OnboardingTemplate.styles';

function OnboardingTemplate({ children }) {
  return (
    <OnboardingBlock>
      <img className="img" src={shapeImg1} alt="" />
      <FormCol>
        <div className="middle-content">{children}</div>
      </FormCol>
      <BannerCol>
        <img className="bgShape" src={shapeImg} alt="description" />
        <div className="brand_logo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="img-box">
          <img src={Img01} alt="" />
        </div>
      </BannerCol>
    </OnboardingBlock>
  );
}

export default OnboardingTemplate;
