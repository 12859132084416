import React from 'react';
import { ModalHolder } from './ConfirmationModal.styles';
import confirmImage from '../../../assets/images/confirmation-img.png';

const Confirmation = ({ message, title = 'Deleted' }) => (
  <ModalHolder>
    <span className="heading">{title} Successfully</span>
    <div className="img-holder">
      <img src={confirmImage} alt="ConfirmImg" />
    </div>
    <span className="text">{message}</span>
  </ModalHolder>
);

export default Confirmation;
