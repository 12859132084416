import React, { useState } from 'react';
import { createContextHook } from 'use-context-hook';

export const SideNavContext = createContextHook({});
export const SideNavContextProvider = props => {
  const [sideNavToggle, setSideNavToggle] = useState(false);

  const toggleSideNav = () => {
    setSideNavToggle(!sideNavToggle);
  };

  return (
    <SideNavContext.Provider
      value={{
        sideNavState: sideNavToggle,
        toggleSideNav,
      }}>
      {props.children}
    </SideNavContext.Provider>
  );
};
