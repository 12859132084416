import styled from 'styled-components/macro';

export const ModalHolder = styled.div`
  padding: 20px 20px 30px;
  .heading {
    display: block;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
  }
  .img-holder {
    max-width: 120px;
    margin: 0 auto 20px;
  }
  .text {
    display: block;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
`;
