import React, { useEffect } from 'react';
import { IoIosClose } from 'react-icons/io';
import { useContextHook } from 'use-context-hook';
import { AuthContext } from 'context/authContext';
import { ContentHolder, Head, StyledModal } from './Modal.styles';

const CenterModal = ({
  children,
  open,
  setOpen,
  bg,
  padding,
  width,
  radius,
  desktopRight,
  desktopTop,
  NoOverflow,
  name = '',
}) => {
  const { hideScroll, setHideScroll } = useContextHook(AuthContext, v => ({
    hideScroll: v.hideScroll,
    setHideScroll: v.setHideScroll,
  }));
  useEffect(() => {
    const disableScroll = () => {
      document.body.style.overflow = 'hidden';
    };
    const enableScroll = () => {
      document.body.style.overflow = 'auto';
    };
    if (open || hideScroll) {
      disableScroll();
    } else {
      enableScroll();
    }
    return () => {
      enableScroll();
    };
  }, [open, hideScroll]);

  const handleClose = () => {
    setOpen(false);
    if (name !== '' && name === 'infoBusiness') {
      setHideScroll(false);
    }
  };

  return (
    open && (
      <StyledModal
        open={open}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            handleClose();
          }
        }}>
        <ContentHolder
          bg={bg}
          padding={padding}
          width={width}
          radius={radius}
          desktopRight={desktopRight}
          desktopTop={desktopTop}
          role="dialog"
          aria-modal="true"
          $NoOverflow={NoOverflow}
          onClick={e => e.stopPropagation()}
          tabIndex={-1}>
          <Head>
            <button type="button" className="closer" onClick={handleClose} tabIndex={0}>
              <IoIosClose size={25} color="var(--white)" />
            </button>
          </Head>
          {children}
        </ContentHolder>
      </StyledModal>
    )
  );
};

export default CenterModal;
