import React from 'react';
import DatePickerHeader from '../DatePickerHeader';
import { StyledDateRange } from './DatePicker.styles';

function DatePicker({
  isClearable,
  prefix,
  suffix,
  disabled,
  excludeDateIntervals,
  invalid,
  error,
  onChange,
  ...props
}) {
  return (
    <StyledDateRange
      disabled={disabled}
      excludeDateIntervals={excludeDateIntervals}
      prefix={prefix}
      suffix={suffix}
      invalid={invalid || error}
      isClearable
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <DatePickerHeader
          date={date}
          changeYear={changeYear}
          changeMonth={changeMonth}
          decreaseMonth={decreaseMonth}
          increaseMonth={increaseMonth}
          prevMonthButtonDisabled={prevMonthButtonDisabled}
          nextMonthButtonDisabled={nextMonthButtonDisabled}
        />
      )}
      {...props}
      onChange={_ => {
        onChange({ target: { value: _, name: props.name } });
      }}
    />
  );
}

export default DatePicker;
