import React from 'react';
import { useContextHook } from 'use-context-hook';
import { Route, Switch } from 'react-router-dom';
import Verify from 'pages/verify-otp';
import ResetPassword from 'pages/reset-password';
import NewPassword from 'pages/create-password';
import PublicRoute from './helpers/publicRoute';
import PrivateRoute from './helpers/privateRoute';
import { AuthContext } from './context/authContext';
import Login from './pages/login';
import Routes from './pages';

function Router() {
  const { isLoggedIn, allowedPages } = useContextHook(AuthContext, v => ({
    isLoggedIn: v.isLoggedIn,
    allowedPages: v.allowedPages,
  }));
  return (
    <Switch>
      <PrivateRoute path="/admin/:view/:child?" component={Routes} isLoggedIn={isLoggedIn} />
      <Route path="/reset-password" render={() => <ResetPassword />} />
      <Route path="/verify-otp" render={() => <Verify />} />
      <Route path="/create-password" render={() => <NewPassword />} />
      <PublicRoute
        restricted
        path="*"
        component={Login}
        isLoggedIn={isLoggedIn}
        redirectTo={allowedPages.length > 0 ? allowedPages[0] : '/dashboard-admin'}
      />
    </Switch>
  );
}
export default Router;
