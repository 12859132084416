import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import adminService from 'services/adminService';
import OnboardingTemplate from 'components/OnboardingTemplate';
import { getCookie } from '../helpers/common';
import { formatTime } from '../helpers/formatTime';
import arrowImg from '../assets/images/half-blue-arrow.svg';
import Button from '../components/atoms/Button';
import { useForm } from '../components/molecules/Form';
import { StyledForm } from '../components/organisms/Login/Login.styles';
import Toast from '../components/molecules/Toast';
import CenterModal from '../components/molecules/Modal/CenterModal';
import Confirmation from '../components/molecules/ConfirmationModal';
import Heading from '../components/atoms/Heading';

function VerifyOtp() {
  const [form] = useForm();
  const [modal, setModal] = useState(false);
  const [isLoading, isSetLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(5).fill(''));
  const [timeLeft, setTimeLeft] = useState({
    seconds: 300,
  });
  const [timerStarted, setTimerStarted] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const inputRefs = useRef([]);
  const email = getCookie('email');
  const router = useHistory();

  useEffect(() => {
    let interval;

    if (timerStarted) {
      interval = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime.seconds > 0) {
            setEnabled(true);
            return { seconds: prevTime.seconds - 1 };
          }
          clearInterval(interval);
          setTimerStarted(false);
          setEnabled(false);
          return { seconds: 0 };
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timerStarted]);

  const startTimer = () => {
    setTimeLeft({ seconds: 300 });
    setTimerStarted(true);
  };

  useEffect(() => {
    startTimer();
  }, []);

  const handleChange = (index, value) => {
    const isValidDigit = /^\d$/.test(value);
    if (isValidDigit) {
      const newOtp = [...otp];
      newOtp[index] = value;

      setOtp(newOtp);

      // Focus on the next input if available
      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value.length === 0) {
      // Clear the current value if the user deletes
      const newOtp = [...otp];
      newOtp[index] = '';

      setOtp(newOtp);

      // Focus on the previous input if available
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e?.key === 'Backspace' && index > 0 && otp[index] === '') {
      // Focus on the previous input on backspace
      inputRefs.current[index - 1].focus();
    }
  };

  const handleOTP = async () => {
    isSetLoading(true);
    try {
      if (email) {
        const otp_code = otp.join('');
        if (!getCookie('request')) {
          setModal(true);
        }

        const res = await adminService.verifyOtp({ email, otp: otp_code });
        if (res) {
          setOtp(new Array(5).fill(''));
          isSetLoading(false);

          // Redirect based on the presence of `req`
          if (getCookie('request')) {
            router.push('/create-password');
          } else {
            // router.push("/dashboard");
          }
        }
      }
    } catch (error) {
      Toast({ type: 'error', message: error?.message });
      isSetLoading(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      if (email) {
        const res = await adminService.resendOtp({ email });
        if (res) {
          startTimer();
          Toast({ type: 'success', message: res?.message });
        }
      }
    } catch (error) {
      Toast({ type: 'error', message: error?.message });
    }
  };

  return (
    <>
      <CenterModal open={modal} setOpen={setModal}>
        <Confirmation title="Saved" message="Saved Changes Successfully!" />
      </CenterModal>
      <OnboardingTemplate>
        <StyledForm form={form}>
          <div className="heading-text">
            <Heading level={1} color="var(--white)">
              Verify OTP!
            </Heading>
            <p>
              We’ve sent you a code on your given email
              <br /> address {email ?? ''}
            </p>
          </div>
          <div className="otp-input-holder">
            {otp.map((value, index) => (
              <input
                placeholder="-"
                key={index}
                type="number"
                maxLength="1"
                value={value}
                onChange={e => handleChange(index, e.target.value)}
                onKeyDown={e => handleKeyDown(index, e)}
                ref={function (input) {
                  inputRefs.current[index] = input;
                }}
              />
            ))}
          </div>
          <div className="flex">
            <button className="resend-code" onClick={handleResendOtp} disabled={enabled} type="button">
              Resend code
            </button>
            <span className="timer">{formatTime(timeLeft.seconds)}</span>
          </div>
          <div className="btn-holder" role="button" tabIndex={0} onClick={handleOTP} onKeyDown={handleKeyDown}>
            <Button type="white" htmlType={isLoading ? 'button' : 'submit'} width="200" rounded loader={isLoading}>
              Confirm
              <img src={arrowImg} alt="arrow" />
            </Button>
          </div>
        </StyledForm>
      </OnboardingTemplate>
    </>
  );
}

export default VerifyOtp;
