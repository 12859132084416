import React, { useEffect, useRef, useState } from 'react';
import { AuthContext } from 'context/authContext';
import Notifications from 'components/Notifications';
import { IoIosArrowDown, IoMdSettings } from 'react-icons/io';
import notificationService from 'services/notificationService';
import { useContextHook } from 'use-context-hook';
import { formatDateAndTime } from 'helpers/common';
import { Link } from 'react-router-dom';
import { NotificationInfo, StyledTopBar, UserInfo } from './TopBar.styles';
import logo from '../assets/images/PayBlisss-blue.svg';
import logoutIcon from '../assets/images/logout-icon.svg';
import avatarIcon from '../assets/images/avatar-icon.svg';

import storeImg from '../assets/images/new-store-icon.png';
import UserImg from '../assets/images/user-icon.png';

const TopBar = () => {
  const { onLogout, user, notification, updateNotification, notificationFetch, hasPermission, referenceNumber } =
    useContextHook(AuthContext, v => ({
      onLogout: v.onLogout,
      user: v.user,
      // fetch: v.fetch,
      // refetch: v.refetch,
      notificationFetch: v.notificationFetch,
      notification: v.notification,
      updateNotification: v.updateNotification,
      hasPermission: v.hasPermission,
      referenceNumber: v.referenceNumber,
    }));
  const modalRef = useRef(null);
  const [toggle, setToggle] = useState(false);

  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    itemsPerPage: 4,
  });

  function handleNotificationDropDown() {
    setToggle(true);
  }

  const handleToggleClass = () => {
    document.body.classList.toggle('nav-active');
  };

  useEffect(() => {
    if (notification || updateNotification) {
      notificationFetch();
    }
  }, [notification, updateNotification]);

  const { notifications_data } = notificationService.GetNotification(searchQuery, notificationFetch);

  const modifyData = notifications_data?.notifications?.items?.map(item => {
    const { date, time } = formatDateAndTime(item.createdAt);

    const createdAtDate = new Date(item.createdAt);
    const now = new Date();
    const timeDifference = Math.abs(now - createdAtDate);
    const twoMinutes = 20 * 60 * 1000;

    return {
      ...item,
      icon: item.message.toLowerCase().includes('business') ? UserImg : storeImg,
      background: item.message.toLowerCase().includes('business') ? '#ecfaff' : '#d7daeb',
      date,
      time,
      tag: timeDifference < twoMinutes ? 'New' : '',
    };
  });

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setToggle(false);
      }
    };

    if (toggle) {
      document.addEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'hidden';
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggle]);

  return (
    <StyledTopBar>
      <div className="menu-holder">
        <button onClick={handleToggleClass} className="nav-btn" type="button">
          <span />
        </button>
        <div className="logoWrapper">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <NotificationInfo className={toggle && 'active'}>
        <div className="user-info">
          {hasPermission('notificaton.view') && (
            <button type="button" className="notification" onClick={() => handleNotificationDropDown()}>
              <div className="img-holder">
                {notifications_data?.unreadCount > 0 && (
                  <span className="notification-count">{notifications_data?.unreadCount}</span>
                )}
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.7099 1.04169C5.63246 1.04169 4.59914 1.4697 3.83728 2.23157C3.07541 2.99343 2.6474 4.02674 2.6474 5.10419V8.27294L1.74823 9.6271C1.64046 9.79327 1.57933 9.98536 1.57123 10.1833C1.56314 10.3811 1.60839 10.5776 1.70224 10.752C1.79609 10.9264 1.9351 11.0724 2.10471 11.1747C2.27432 11.2769 2.4683 11.3317 2.66636 11.3334H10.7534C10.9528 11.3327 11.1482 11.2781 11.3191 11.1754C11.4899 11.0727 11.6298 10.9258 11.7239 10.75C11.818 10.5743 11.8628 10.3764 11.8536 10.1773C11.8444 9.97818 11.7815 9.78526 11.6716 9.61898L10.7724 8.27294V5.10419C10.7724 4.02674 10.3444 2.99343 9.58252 2.23157C8.82065 1.4697 7.78734 1.04169 6.7099 1.04169ZM6.7099 12.9584C7.04562 12.958 7.37299 12.8536 7.64699 12.6596C7.921 12.4656 8.1282 12.1915 8.24011 11.875H5.17969C5.2916 12.1915 5.49879 12.4656 5.7728 12.6596C6.04681 12.8536 6.37417 12.958 6.7099 12.9584Z"
                    fill={notifications_data?.unreadCount > 0 ? 'red' : '#313131'}
                  />
                </svg>
              </div>
              <span className="text">Notifications</span>
              <div className="dropdown-holder" ref={modalRef}>
                {toggle && (
                  <Notifications
                    data={modifyData}
                    setSearchQuery={setSearchQuery}
                    totalItems={notifications_data?.totalItems}
                  />
                )}
              </div>
            </button>
          )}
          {referenceNumber && referenceNumber !== '' && <div> Reference #: {referenceNumber} </div>}
          <UserInfo
            onMouseOver={() => {
              setToggle(false);
            }}>
            <div className="user-img">
              <img src={avatarIcon} width={40} height={40} alt="user img" />
            </div>
            <IoIosArrowDown className="icon" />
            <div className="user-dropdown">
              <div className="wrap">
                <div className="img-box">
                  <img src={avatarIcon} width={95} height={95} alt="user img" />
                </div>
                <div className="text-box">
                  <span className="title">{user?.username}</span>
                  <span className="email">{user?.email}</span>
                </div>
              </div>
              <ul className="list">
                <li>
                  <Link to="/admin/settings">
                    <IoMdSettings size="20" />
                    Account Settings
                  </Link>
                </li>
              </ul>
              <button onClick={onLogout} type="button" className="btn-logout">
                <img src={logoutIcon} width="20" alt="logout" />
                logout
              </button>
            </div>
          </UserInfo>
        </div>
      </NotificationInfo>
    </StyledTopBar>
  );
};

export default TopBar;
