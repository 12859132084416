import React, { useState, useEffect } from 'react';
import { createContextHook } from 'use-context-hook';
import { clearCookie, getCookie, setCookie } from 'helpers/common';
// import socket from 'helpers/socket';
import Toast from 'components/molecules/Toast';
import adminService from 'services/adminService';
import { useCancellablePromise } from 'helpers/promiseHandler';

export const AuthContext = createContextHook({});
export const AuthContextProvider = props => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!getCookie(process.env.REACT_APP_ADMIN_PAYZEE_TOKEN_COOKIE));
  // const access_token = getCookie('_loadpzt');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [loading_user, setLoadingUser] = useState(false);
  const [fetch_user, setFetchUser] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [affiliate, setAffiliate] = useState(false);
  const { cancellablePromise } = useCancellablePromise();
  const [reFetch, setRefetch] = useState(false);
  const [notificationFetch, setNotificationFetch] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [hideScroll, setHideScroll] = useState(false);
  const [allowedPages, setAllowedPages] = useState(
    JSON.parse(getCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE)) || [],
  );
  const [notification, setNotification] = useState({});
  const [updateNotification, setUpdateNotification] = useState(0);
  // const [isConnect, setIsConnect] = useState(false);

  const onLogout = async () => {
    try {
      await adminService.removeAdminJwt();
    } catch (err) {
      // Toast({
      //   type: 'error',
      //   message: err.message,
      // });
    } finally {
      Toast({ type: 'success', message: 'Logged out Successfully!' });
      clearCookie(process.env.REACT_APP_ADMIN_PAYZEE_TOKEN_COOKIE);
      clearCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE);
      setIsLoggedIn(prev => !prev);
      setAffiliate(false);
      setReferenceNumber('');
    }
  };

  const getPermissions = () => {
    setLoadingUser(true);
    cancellablePromise(adminService.getCurrentAdmin())
      .then(res => {
        setAllowedPages([...res.permissions.filter(p => p.includes('.nav')).map(p => p.split('.')[0])]);
        setCookie(
          process.env.REACT_APP_ALLOWED_PAGES_COOKIE,
          JSON.stringify([...res.permissions.filter(p => p.includes('.nav')).map(p => p.split('.')[0])]),
        );
        setUser(res);
        if (res?.role_type?.length === 1 && res?.role_type[0] === 'AFFILIATE') {
          setAffiliate(true);
          setReferenceNumber(res?.reference_number);
        }
        setLoadingUser(false);
      })
      .catch(err => {
        setAllowedPages(['no-permissions']);
        setCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE, JSON.stringify(['no-permissions']));
        setLoadingUser(false);
        Toast({
          type: 'error',
          message: err.message,
        });
      });
  };
  /**
   * @description - This function is used to fetch the user details from the server
   */
  useEffect(() => {
    if (isLoggedIn) {
      getPermissions();
      // setIsConnect(true);
    }
  }, [isLoggedIn]);

  const onLogin = async ({ email, password }) => {
    setLoadingUser(true);
    try {
      const res = await adminService.login({
        email,
        password,
      });

      if (!res?.token) {
        throw new Error(res?.message);
      }
      setCookie(process.env.REACT_APP_ADMIN_PAYZEE_TOKEN_COOKIE, res.token);
      setIsLoggedIn(true);
      setLoadingUser(false);
      Toast({
        type: 'success',
        message: 'Logged In Successfully!',
      });
    } catch ({ message }) {
      setIsLoggedIn(false);
      setLoadingUser(false);
      Toast({ type: 'error', message });
    }
  };

  /**
   * @description - If someone tries to temper with the cookies we take the appropriate action
   */
  useEffect(() => {
    function listenCookieChange(callback, interval) {
      let old_bap_token = getCookie(process.env.REACT_APP_ADMIN_PAYZEE_TOKEN_COOKIE);
      let old_allowed = getCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE);
      setInterval(() => {
        const new_bap_token = getCookie(process.env.REACT_APP_ADMIN_PAYZEE_TOKEN_COOKIE);
        const new_allowed = getCookie(process.env.REACT_APP_ALLOWED_PAGES_COOKIE);
        if (new_bap_token !== old_bap_token) {
          try {
            callback(new_bap_token, process.env.REACT_APP_ADMIN_PAYZEE_TOKEN_COOKIE);
          } finally {
            old_bap_token = new_bap_token;
          }
        }
        if (new_allowed !== old_allowed) {
          try {
            callback(new_allowed, process.env.REACT_APP_ALLOWED_PAGES_COOKIE);
          } finally {
            old_allowed = new_allowed;
          }
        }
      }, interval);
    }
    const intervalId = listenCookieChange((value, cookie) => {
      if (cookie === process.env.REACT_APP_ADMIN_PAYZEE_TOKEN_COOKIE) {
        if (!value) {
          // onLogout();
        }
      }
      if (cookie === process.env.REACT_APP_ALLOWED_PAGES_COOKIE) {
        if (JSON.stringify(allowedPages) !== value && isLoggedIn) {
          // getPermissions();
        }
      }
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const hasPermission = perm => user?.permissions?.includes(perm);
  return (
    <AuthContext.Provider
      value={{
        setIsLoggedIn,
        onLogout,
        onLogin,
        refetch: () => setRefetch(_ => !_),
        fetchUser: () => setFetchUser(() => !fetch_user),
        notificationFetch: () => setNotificationFetch(() => !notificationFetch),
        setShowTokenModal,
        setLoading,
        hasPermission,
        setUser,
        allowedPages,
        showTokenModal,
        loading,
        isLoggedIn,
        fetch: reFetch,
        user,
        loading_user,
        setNotification,
        notification,
        setUpdateNotification,
        affiliate,
        referenceNumber,
        setHideScroll,
        hideScroll,
        updateNotification,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};
