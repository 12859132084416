import { io } from 'socket.io-client';

let socket = null;

const SOCKET_URL = process.env.REACT_APP_ADMIN_API_URL;

export const connectionWithSocketServer = (token, handleNotification, updateNotification) => {
  const jwtToken = token;
  socket = io(SOCKET_URL, {
    auth: {
      token: jwtToken,
      type: 'admin',
    },
  });

  socket.on('connect', () => {
    console.log('User Connected');
  });

  socket.on('disconnect', () => {
    console.log('User disconnected');
  });

  socket.on('admin_side_notifications', data => {
    handleNotification(data);
  });

  socket.on('notification-updated', data => {
    updateNotification(data);
  });

  // socket.on('online-users', data => {
  //   const { onlineUsers } = data;
  //   console.log('onlineUsers', onlineUsers);
  // });
};

export const socketServer = () => socket;
