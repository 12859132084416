import React, { useState } from 'react';
import OnboardingTemplate from 'components/OnboardingTemplate';
import { useHistory } from 'react-router-dom';
import adminService from 'services/adminService';
import { setCookie } from 'helpers/common';
import arrowImg from '../assets/images/half-blue-arrow.svg';
import Button from '../components/atoms/Button';
import Field from '../components/molecules/Field';
import Form, { useForm } from '../components/molecules/Form';
import { StyledForm } from '../components/organisms/Login/Login.styles';
import Heading from '../components/atoms/Heading';
import Toast from '../components/molecules/Toast';

function ResetPassword() {
  const [form] = useForm();
  const router = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async data => {
    try {
      setIsLoading(true);
      const res = await adminService.resendOtp(data);
      setCookie('email', data?.email);
      setCookie('request', 'reset-password');
      Toast({
        type: 'success',
        message: res?.message,
      });
      router.push('/verify-otp');
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <OnboardingTemplate>
      <Heading level={1} color="var(--white)">
        Reset Password
      </Heading>
      <StyledForm form={form} onSubmit={onSubmit}>
        <p>
          Submit your email address to initiate a<br /> password reset request.
        </p>
        <Form.Item
          label="Email Address"
          type="email"
          rounded
          name="email"
          placeholder="Email Address"
          rules={[
            {
              required: true,
              message: 'Email is Required',
            },
          ]}>
          <Field />
        </Form.Item>
        <div className="btn-holder">
          <Button htmlType={isLoading ? 'button' : 'submit'} rounded loader={isLoading} type="white" width="210">
            Send OTP
            <img src={arrowImg} alt="arrow" />
          </Button>
        </div>
      </StyledForm>
    </OnboardingTemplate>
  );
}

export default ResetPassword;
