import { getCookie } from 'helpers/common';
import { connectionWithSocketServer, socketServer } from 'helpers/socketConnection/socketConnection';
import { useContextHook } from 'use-context-hook';
import React, { createContext, useEffect, useState } from 'react';
import { AuthContext } from './authContext';

const context = {
  socket: null,
};

export const SocketContext = createContext(context);

export function SocketContextProvider(props) {
  const { isLoggedIn, setNotification, setUpdateNotification } = useContextHook(AuthContext, v => ({
    isLoggedIn: v.isLoggedIn,
    setNotification: v.setNotification,
    setUpdateNotification: v.setUpdateNotification,
  }));
  const access_token = getCookie('_loadpzt');
  const [socket, setSocket] = useState(null);

  const handleUpComingNotifications = data => {
    setNotification(data);
  };

  const updateNotification = data => {
    if (data) {
      setUpdateNotification(prev => prev + 1);
    }
  };

  useEffect(() => {
    if (access_token || isLoggedIn) {
      setTimeout(() => {
        connectionWithSocketServer(access_token, handleUpComingNotifications, updateNotification);
        setSocket(socketServer());
      }, 1000);
    }
    return () => {
      if (socketServer()) {
        socketServer().off('connect');
        socketServer().off('disconnect');
        socketServer().off();
      }
    };
  }, [access_token, isLoggedIn]);

  return <SocketContext.Provider value={{ socket }}>{props.children}</SocketContext.Provider>;
}
