import styled from 'styled-components/macro';

export const OnboardingBlock = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  background: #36469a;

  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 700px;
  }
  .middle-content {
    width: 100%;
    text-align: center;
  }
`;
export const FormCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 50%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(20px);
`;

export const BannerCol = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding: 20px 20px 0;
  z-index: 1;
  @media (max-width: 991px) {
    display: none;
  }

  .brand_logo {
    max-width: 150px;
    align-self: flex-end;

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .img-box {
    max-width: 620px;
    align-self: center;
  }

  .bgShape {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
`;
