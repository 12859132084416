import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { PhoneWrapper } from './PhoneNumber.styles';

const PhoneNumberInput = ({ onChange, value, placeholder }) => (
  <PhoneWrapper>
    <PhoneInput placeholder={placeholder} value={value} onChange={onChange} international maxLength={16} />
  </PhoneWrapper>
);

export default PhoneNumberInput;
