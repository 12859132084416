import { useEffect, useState } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const notificationService = {
  _url: `${process.env.REACT_APP_ADMIN_API_URL}/api/admin`,

  GetNotification(searchQuery, fetch) {
    const [data, setData] = useState({
      notifications: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getNotification(searchQuery))
        .then(res => {
          setData(res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [searchQuery, fetch]);
    return {
      notifications_loading: status === STATUS.LOADING,
      notifications_error: status === STATUS.ERROR ? status : '',
      notifications_data: data,
    };
  },

  async getNotification({ page = 1, itemsPerPage = 4, targetRole = 'SUPER_ADMIN' }) {
    const res = await Fetch.get(
      `${this._url}/get-notifications?itemsPerPage=${itemsPerPage}&page=${page}&targetRole=${targetRole}`,
    );
    if (res.status >= 200 && res.status < 300) {
      const data = await res.json();
      const { notifications, totalItems, unreadCount } = data;
      return {
        notifications,
        totalItems,
        unreadCount,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async health() {
    const res = await Fetch.get(`${this._url}/notification/health`);
    if (res.status >= 200 && res.status < 300) {
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};
export default notificationService;
